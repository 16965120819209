@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.under-construction {
  height: 100vh;
  position: relative;

  .controls {
    position: absolute;
    display: flex;
    top: 10px;
    right: 10px;

    > * {
      margin-left: 5px;
    }
  }
}

.social-icons {
  position: absolute;
  right: .5rem;
  bottom: 50%;
  z-index: 2;

  a {
    color: var(--primary-bg);

    &:hover {
      color: var(--primary-bg);
      filter: brightness(0.8);
    }
  }
}

@include media-breakpoint-up(md) {
  .social-icons {
    right: 2.5rem;
    bottom: 45%;
  }
}
