.contact {
  background-color: var(--secondary-bg);
  color: var(--primary-text);

  a {
    text-decoration: none;
  }
}

.map {
  width: 100%;
  height: 350px;
  background-color: var(--secondary-bg);
}
