@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';
@import '../../globals';

.main-navbar {
  background-color: var(--primary-bg);
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 3;
  transform: translateY(0px);
  transition: 0.3s;
  height: $navbar-height;
  display: block;

  .nav-link {
    color: var(--primary-text);
  }
  

  &.hidden {
    transform: translateY(-$navbar-height);
    display: none;
  }
}

.controls {
  > * {
    margin-left: 10px;
  }
}

@include media-breakpoint-down(md) {
  .main-navbar {
    position: fixed;
    top: unset;
    bottom: 0;
    transform: unset;
    transform: translateY(0);

    &.hidden {
      transform: translateY($navbar-height);
    }
  }

  .controls {
    position: fixed;
    top: 15px;
    right: 15px;
    z-index: 4;
  }
}

.mobile-menu {
  height: calc(100vh - $navbar-height);
  position: fixed;
  top: 0;
  width: 100vw;
  background-color: var(--primary-bg);
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 20px 40px 40px 20px;
  text-align: right;
  color: var(--primary-text);

  .nav-link {
    font-size: 1.5em;
  }
}

.mobile-menu-btn {
  color: var(--primary-text);

  &:hover, &:active, &:link, &:visited {
    color: var(--primary-text);
  }
}


