$dark: #212529;
$light: #f8f9fa;
$primary: #019e55;
$secondary: #edefef;

$navbar-height: 56px;

:root {
  --primary: #{$primary};
  --secondary: #{$secondary};
  --primary-text: #{$dark};
  --primary-btn: #{$light};
  --primary-bg: #{$light};
  --secondary-bg: #fff;
}

[data-theme='dark'] {
  --primary: #{$primary};
  --secondary: #{$secondary};
  --primary-text: #{$light};
  --primary-btn: #{$dark};
  --primary-bg: #{$dark};
  --secondary-bg: #353b43;
}

a {
  text-decoration: none;
  color: var(--primary-text);

  &:hover {
    color: var(--primary-text);
    filter: brightness(0.7);
  }
}
