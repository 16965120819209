@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';
@import './globals';

.landing-page {
  position: relative;

  @include media-breakpoint-down(md) {
    top: 0;
  }
}


