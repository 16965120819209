@import '../../globals.scss';

.language-selector {
  border: 0;
  width: auto;
  padding: 5px 8px;
  background-color: var(--primary-bg);
  color: var(--primary-text);
  background-image: unset;
  cursor: pointer;
  border: 0;

  &:hover, &:active, &:focus-visible, &:focus-visible {
    filter: brightness(0.9);
    color: var(--primary-text);
    background-color: var(--primary-bg);
    border: 0;
  }
}
