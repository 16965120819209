.theme-switcher {
  color: var(--primary-text);
  background-color: var(--primary-bg);
  padding: 5px 10px;
  border: 0;

  &:hover, &:active, &:focus-visible, &:focus-visible {
    filter: brightness(0.9);
    color: var(--primary-text);
    background-color: var(--primary-bg);
    border: 0;
  }
}
