@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';
@import '../../globals';

.masthead {
  position: relative;
  overflow: hidden;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-text);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--primary-bg);
    transform: skewX(-9deg);
    transform-origin: top right;
  }

  .masthead-content {
    position: relative;
    max-width: 40rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
    font-weight: 200;
  }

  .logo {
    width: 100%;
    max-width: 250px;
  }
}

@include media-breakpoint-down(md) {
  .landing-page {
    background: url('../../assets/background.jpg') #019e55 no-repeat top
      center;
    background-size: cover;
    height: 100vh;
  }

  .masthead {
    position: absolute;
    width: 100%;
    bottom: 0;

    &:before {
      transform: skewY(-9deg);
      transform-origin: top right;
    }

    .masthead-content {
      padding-bottom: 1rem;
    }
  }
}

@include media-breakpoint-up(md) {
  .landing-page {
    background: url('../../assets/background.jpg') #019e55 no-repeat top right
      scroll;
    background-size: contain;
    height: 100%;
  }

  .masthead {
    height: 100%;
    min-height: 0;
    padding-bottom: 0;

    &:before {
      transform: skewX(-9deg);
      transform-origin: top right;
    }

    .masthead-content {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 2rem;
      padding-right: 9rem;
    }
  }
}

@include media-breakpoint-up(md) {
  .masthead {
    width: 50vw;
  }
}

@include media-breakpoint-up(lg) {
  .masthead {
    width: 60vw;
  }
}

@include media-breakpoint-up(xl) {
  .masthead {
    width: 65vw;
  }
}
